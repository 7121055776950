import { Component, OnInit } from '@angular/core';
import { MenuFirebaseService } from 'src/app/service/firebase/menu.firebase.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit {
  constructor(public menuservice: MenuFirebaseService) {
    console.log('Base');
  }

  ngOnInit(): void {}
}
