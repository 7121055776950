import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BaseComponent } from './_layout/base/base.component';
import { ErrorPageComponent } from './_layout/error-page/error-page.component';
import { FooterComponent } from './_layout/footer/footer.component';
import { HeaderComponent } from './_layout/header/header.component';
import { IndustrialComponent } from './component/home/industrial/industrial.component';
import { SystemsComponent } from './component/home/systems/systems.component';
import { DesignComponent } from './component/home/design/design.component';
import { ContactComponent } from './component/home/contact/contact.component';
import { FaqComponent } from './component/home/faq/faq.component';
import { ProductsDetaillComponent } from './component/products-detaill/products-detaill.component';
//firebase
import { environment } from '../environments/environment';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';

@NgModule({
  declarations: [
    AppComponent,
    BaseComponent,
    FooterComponent,
    ErrorPageComponent,
    HeaderComponent,
    IndustrialComponent,
    SystemsComponent,
    DesignComponent,
    ContactComponent,
    FaqComponent,
    ProductsDetaillComponent,
  ],
  imports: [BrowserModule, AppRoutingModule],
  exports: [BrowserModule, FormsModule, ReactiveFormsModule, AppComponent],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
