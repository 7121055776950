import { Component, OnInit } from '@angular/core';
import { MenuFirebaseService } from 'src/app/service/firebase/menu.firebase.service';
import { MenuItem } from 'src/app/models/menuitem.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isNavbarCollapsed = true;

  navbarItems: Observable<MenuItem[]> = new Observable<MenuItem[]>();

  toggleNavbar() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  constructor(public menuservice: MenuFirebaseService) {
    this.navbarItems = menuservice.Menu;
    console.log(this.navbarItems);
  }

  ngOnInit(): void {}
}
