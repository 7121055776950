import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-products-detaill',
  templateUrl: './products-detaill.component.html',
  styleUrls: ['./products-detaill.component.scss'],
})
export class ProductsDetaillComponent implements OnInit {
  Idproduct: string = '';

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const key = params['IdProduct'];
      // Use the 'key' value as needed in your component
      this.Idproduct = key;
    });
  }
}
