import { BaseComponent } from './_layout/base/base.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from './_layout/error-page/error-page.component';

const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./component/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'error',
        component: ErrorPageComponent,
        data: {
          type: 404,
          title: 'Page Not Found',
          desc: "Oopps!! The page you were looking for doesn't exist.",
        },
      },
    ],
  },

  {
    path: 'error/:type',
    component: ErrorPageComponent,
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
