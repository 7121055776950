<!-- Navigation-->
<nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
  <div class="container px-5">
    <a class="navbar-brand" href="index.html">
      <img src="../../../assets/OneZeros/favicon.ico.png" alt="" height="30" />
      Ones & Zeros</a
    >
    <button
      class="navbar-toggler"
      type="button"
      (click)="toggleNavbar()"
      aria-controls="navbarSupportedContent"
      [attr.aria-expanded]="isNavbarCollapsed ? 'true' : 'false'"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="collapse navbar-collapse"
      [ngClass]="{ show: !isNavbarCollapsed }"
      id="navbarSupportedContent"
    >
      @if(navbarItems | async; as NavItemsObservable){
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li class="nav-item" *ngFor="let item of NavItemsObservable">
          <a
            class="nav-link"
            [href]="item.external ? item.link : null"
            [routerLink]="!item.external ? item.link : null"
          >
            {{ item.label }}
          </a>
        </li>
      </ul>
      }
    </div>
  </div>
</nav>
