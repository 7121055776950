import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  getDocs,
  query,
  DocumentData,
  orderBy,
} from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { MenuItem } from 'src/app/models/menuitem.model';

@Injectable({
  providedIn: 'root',
})
export class MenuFirebaseService {
  private _menu$ = new BehaviorSubject<MenuItem[]>([]);
  private dataStore: { _Menu: MenuItem[] } = { _Menu: [] };
  readonly Menu = this._menu$.asObservable();

  constructor(public firestore: Firestore) {
    console.log('Inicia menu');
    this.getmenu().then((data: MenuItem[]) => {
      this.dataStore._Menu = data;
      this._menu$.next(Object.assign({}, this.dataStore)._Menu);
    });
  }

  async getmenu() {
    const querySnapshot = await getDocs<MenuItem, DocumentData>(
      query(collection(this.firestore, 'menu'), orderBy('order'))
    );
    return querySnapshot.docs.map((menu) => menu.data());
  }
}
